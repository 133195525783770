var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar",class:{ preview: _vm.preview },attrs:{"id":"webinar__element"}},[(_vm.hasAvailableSession)?_c('div',{staticClass:"calendar__day-slots"},[_c('div',{staticClass:"w-100 text-center calendar__title"},[_vm._v(_vm._s(_vm.calendarTitle))]),_vm._v(" "),_c('div',{staticClass:"w-100 text-center calendar__timezone"},[_vm._v(_vm._s(_vm.timezone))]),_vm._v(" "),_c('div',{staticClass:"d-flex calendar__time-slots",style:(_vm.isDemo ? 'padding-left: 16px; padding-right: 16px;' : '')},[_vm._l((_vm.currentSessions),function(slot,index){return (!_vm.isLoading)?_c('div',{staticClass:"calendar__time-slot"},[_c('div',{staticClass:"calendar__time-slot-button",class:{
						selected: !_vm.isDemo
							? _vm.activeTime !== null && _vm.activeTime.booking_id === slot.booking_id
							: _vm.activeTime !== null && _vm.activeTime.date === slot.date && _vm.activeTime.stime === slot.stime,
						disabled: slot.available_seats < 1,
						invisible: slot.placeholder,
					},on:{"click":function($event){return _vm.selectTime(slot)}}},[(!slot.placeholder)?_c('div',{class:[
							'calendar__time',
							{
								'mx-0': _vm.isDemo,
							} ]},[_c('div',[_vm._v(_vm._s(slot.date))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(slot.stime)+" - "+_vm._s(slot.end_time))])]):_c('div',{staticClass:"calendar__time"},[_vm._v("-")])])]):_vm._e()}),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"w-100 d-flex calendar__loader align-items-center justify-content-center",staticStyle:{"transition":"0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms"}},[_c('HollowDotsSpinner',{attrs:{"animation-duration":1000,"dot-size":12,"dots-num":3,"color":_vm.primaryColor}})],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[(_vm.hasPrevSessions || _vm.hasNextSessions)?_c('div',{staticClass:"session-button-container"},[_c('div',{staticClass:"sessions-button",class:{ disabled: !_vm.hasPrevSessions },on:{"click":function($event){return _vm.navigateSessions('prev')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 11.95 11","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('path',{attrs:{"d":"M7.05852 9.5L2.94138 5L7.05852 0.5","fill":"none","stroke-width":"1"}})])]),_vm._v(" "),_c('div',{staticClass:"sessions-button next",class:{ disabled: !_vm.hasNextSessions },on:{"click":function($event){return _vm.navigateSessions('next')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 11.95 11","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('path',{attrs:{"d":"M7.05852 9.5L2.94138 5L7.05852 0.5","fill":"none","stroke-width":"1"}})])])]):_vm._e()])]):_c('div',{staticClass:"text-center no-upcoming-label"},[_vm._v("Currently, there are no upcoming events. Please check back later!")])])}
var staticRenderFns = []

export { render, staticRenderFns }